import { Input } from '@mehilainen/design-system'
import InputContainer from '../layouts/InputContainer'
import React from 'react'

type Props = {
  password: string
  setPassword: (value: string) => void
  changingPassword?: boolean
  disabled?: boolean
}
export const PasswordField = ({
  password,
  setPassword,
  changingPassword,
  disabled,
}: Props) => {
  function handlePasswordInputChange(value: string) {
    setPassword(value)
  }

  return (
    <InputContainer>
      <Input
        required
        id="password"
        label={changingPassword ? 'Nykyinen salasana *' : 'Salasana *'}
        placeholder={
          changingPassword ? 'Syötä nykyinen salasana' : 'Syötä salasana'
        }
        value={password}
        onChange={e => handlePasswordInputChange(e.target.value)}
        type="password"
        disabled={disabled}
      />
    </InputContainer>
  )
}
