import { Button, H1, H3 } from '@mehilainen/design-system'
import { Link, navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'

import ContentContainer from 'components/ContentContainer'
import { LoginContext } from 'state/providers/LoginProvider'
import { LoginData } from 'requests/magento_login/loginCustomer'
import LoginForm from 'components/forms/LoginForm'
import { NotificationContext } from 'state/providers/NotificationProvider'
import { loginGA } from 'gtag.js/utils'
import notForLoggedInUsers from 'utils/login/notForLoggedInUsers'
import styled from 'styled-components'
import { unexpectedErrorDefaultMessage } from 'utils/errorHandling'

export default () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { isLoggedIn, loginCustomer, logoutCustomer } = useContext(LoginContext)
  const { setNotification } = useContext(NotificationContext)

  const handleLogin = async ({ email, password }: LoginData): Promise<void> => {
    setLoading(true)
    let newCustomerToken
    try {
      newCustomerToken = await loginCustomer(password, email)
      loginGA()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      newCustomerToken && logoutCustomer(newCustomerToken)
      setNotification(
        {
          type: 'error',
          message: getAuthenticationError(err),
        },
        7000
      )
      console.error('Customer login failed', err)
      return
    }
    navigate('/profile')
  }

  useEffect(() => {
    notForLoggedInUsers(isLoggedIn, '/profile')
  }, [isLoggedIn])

  return (
    <ContentContainer>
      <Header>
        <H1>Kirjaudu sisään</H1>
        <p>Kirjaudu sisään jos olet palaava asiakas tai luo uusi tili.</p>
      </Header>
      <Columns>
        <div>
          <LoginForm
            loading={loading}
            heading="Palaava asiakas"
            handleLogin={handleLogin}
          />
        </div>

        <div>
          <H3>Uusi asiakas?</H3>
          <p>
            Tilin luomisesta on monia etuja: nopeammat tilaukset, säilytä useita
            osoitteita, seuraa tilauksia ja muuta.
          </p>
          <Link to="/create">
            <Button secondary large>
              LUO UUSI TILI
            </Button>
          </Link>
        </div>
      </Columns>
    </ContentContainer>
  )
}

export const getAuthenticationError = (error: unknown): string => {
  let message = unexpectedErrorDefaultMessage
  if (error instanceof Error) {
    const magentoError = error.message.toLowerCase()
    if (magentoError.includes('invalid')) {
      message = 'Virheelliset kirjautumistiedot'
    } else if (magentoError.includes('incorrect')) {
      message = 'Virheellinen sisäänkirjautuminen tai lukittu käyttäjätunnus'
    }
  }
  return message
}

const Header = styled.div`
  text-align: center;
  display: grid;
  gap: calc(var(--content-container-gap) * 0.5);
`

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    > a {
      width: 100%;
      > button {
        width: 100%;
      }
    }
  }

  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    justify-content: center;
    flex-direction: row;

    > div {
      max-width: 550px;
      > a {
        width: 50%;
      }
    }
  }
`
