import { Button, H3 } from '@mehilainen/design-system'
import React, { useState } from 'react'

import { EmailField } from './fields/EmailField'
import { Link } from 'gatsby'
import { LoginData } from 'requests/magento_login/loginCustomer'
import { PasswordField } from './fields/PasswordField'
import styled from 'styled-components'

type Props = {
  handleLogin: (data: LoginData) => void
  heading?: string
  loading: boolean
}

export default ({ handleLogin, heading, loading }: Props) => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const disabled = !email || !password

  return (
    <LoginForm>
      {heading ? <H3>{heading}</H3> : null}
      <EmailField email={email} setEmail={setEmail} />
      <PasswordField password={password} setPassword={setPassword} />
      <Buttons>
        <Button
          large
          disabled={disabled}
          loading={loading}
          onClick={e => {
            e.preventDefault()
            handleLogin({ email, password })
          }}
        >
          Kirjaudu sisään
        </Button>
        <Link to="/password">
          <Button type="button" secondary large>
            Unohditko salasanan?
          </Button>
        </Link>
      </Buttons>
    </LoginForm>
  )
}

const LoginForm = styled.form`
  display: grid;
  grid-template-columns: minmax(min(80vw, 388px), 616px);
  gap: 16px 0;
`
const Buttons = styled.div`
  --gap: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  flex-direction: column;
  align-content: center;
  button,
  & > a {
    width: 100%;
  }
  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    flex-direction: row;
    justify-content: space-between;
    & > button,
    & > a {
      width: calc(50% - var(--gap));
    }
  }
`
